
@charset "utf-8";
@import "./bootstrap/bootstrap.scss"; // (1)
@import "variables";  // (2)
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import "lity";

a{
    color: theme-color("links");
}

.image-container {
    position: relative;
}

.col-center{
    margin: 0 auto;
  }

.mycover {
    min-width: 100%;
    object-fit: cover;
  }

.content {
    padding: 0 1em;
    max-width: 1040px;
    margin: 0px auto;
}

.footer {
    padding: 1em 1em 3em;
}

hr {
    border: none;
    border-top: 1px solid #CCC;
}

.news{
    padding: 0;
    color: $gray-500;
}

.news-item {
    font-size: .8em;
    list-style: none;
    margin-top:10px;
    margin-bottom:10px;
    text-align: left;
    a{
        color:theme-color("links");
    }
}

.news-image{

}

.news-date {
    color: nth(nth($custom-colors, -2),2);
}

.people-list {
    a{
        color:white;
    }
    list-style-type: none;
    h2 {
        font-size: 16px;
        margin: 1em 0;
    }
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
  }

.people-item {
    display: inline-block;
    margin-right: 2em;
    margin-bottom: 1em;
    font-size: 13px;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

    &:hover {
        background: rgba(255, 255, 255, 0.10);
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
    }

    &.people-item-clickable {
        cursor: pointer;

    }

    .photo {
        display: inline-block;
        width: 150px;
        height: 180px;
        background-position: center center;
        background-size: cover;
        vertical-align: top;
    }
    .info {
        display: inline-block;
        width: 330px;
        height: 180px;
        vertical-align: top;
        padding: 5px 10px;
        overflow: hidden;

        & > div {
            line-height: 1.5em;
            margin-bottom: 5px;

            &.name {
                font-weight: bold;
            }

            &.description {
                font-size: 11px;
                color: #AAA;
            }
        }
    }
}

.publication-item {
    a{
        color: theme-color("links");
    }
    margin-bottom: 0.5em;

    color: $gray-500;

    .publication-title {
        font-weight: bold;
        display: block;
        color: $white;
    }
    .publication-authors, .publication-controls {
        display: block;
        color: $gray-300;
        font-size: 0.9em;
    }
    .publication-where {
        font-style: italic;
        display: block;
        font-size: 0.9em;
    }
    .publication-controls {
        font-size: 0.9em;
        & > span:not(:first-child) {
            &:before {
                content: " | ";
                color: #CCC;
            }
        }
        & > span:first-child {
            &:before {
                content: "[ ";
                color: #CCC;
            }
        }
        & > span:last-child {
            &:after {
                content: " ]";
                color: #CCC;
            }
        }
    }
}

.publication-mainpage{
    color: $gray-500;
    li{
        margin-bottom: 10px;
    }
    span{
        display:inline-block;
        margin: 0;
        padding: 0;
        border: none;

        a{
            color: white;
        }
    }
    padding: 0;
    list-style-type:none;
    font-size: 0.8em;
    .publication-title-mainpage{
        font-weight: normal;
    }
    .publication-authors-mainpage{
        font-size: 0.8em;
    }
    .publication-where-mainpage {
        font-size: 0.8em;
        font-style: italic;
    }
    .publication-controls-mainpage{
        a{
            color:white;
        }
    }
}


.active{
    color:white;
}

.header {
    h1, small {
        display: inline;
    }
    small {
        margin-left: 1em;
    }
    margin: 1em 0;
    padding: 1em;
    margin-bottom: 0;
    background-image: url(../images/banner.png);
	background-color: #ffffff;
}

.research-areas-areas {
    ul {
        list-style: none;
        padding: 0;
    }
    li {
        background-color: rgba(144, 172, 255, 0.048);
        margin: 0;
        a:hover{
           text-decoration:none;
        }
        a {
            color: theme-color("links");
            display: block;
            padding: 5px 10px;
        }
        &.active {
            background-color: #55688a;
        }
    }
}

#graph-area{
    background-color: white;

}

#years-view{


    text-align: center;
    padding-top:10px;
    padding-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.377);
}


.research-areas-projects {

    li {
        margin-bottom: 1em;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .image {
            width: 100px;
            height: 100px;
            background-position: center center;
            background-size: cover;
            border-radius: 4px;
            align-self: center;
            flex-shrink: 0;
        }
        .content {
            width: 100%;
            align-self: center;
        }
    }
}

.research-areas-list-view {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;


    .research-areas-projects {
        margin-top:100px;
        li {
            margin-bottom: 1em;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            .image {
                width: 100px;
                height: 100px;
                background-position: center center;
                background-size: cover;
                border-radius: 4px;
                align-self: center;
                flex-shrink: 0;
            }
            .content {
                width: 100%;
                align-self: center;
            }
        }
    }

    ul {
        list-style-type: none;
        margin: 0; padding: 0;
    }
}

.d3-tip {
      line-height: 1;
      padding: 6px;
      background: rgba(0, 0, 0, 0.8);
      color: #fff;
      border-radius: 4px;
      font-size: 12px;
    }

    /* Creates a small triangle extender for the tooltip */
    .d3-tip:after {
      box-sizing: border-box;
      display: inline;
      font-size: 10px;
      width: 100%;
      line-height: 1;
      color: rgba(0, 0, 0, 0.8);
      content: "\25BC";
      position: absolute;
      text-align: center;
    }

    /* Style northward tooltips specifically */
    .d3-tip.n:after {
      margin: -2px 0 0 0;
      top: 100%;
      left: 0;
    }


    .custom-scroll::-webkit-scrollbar-track
    {
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: rgba(0, 0, 0, 0.04);
    }

    .custom-scroll::-webkit-scrollbar
    {
        width: 10px;
        background-color: rgba(0, 0, 0, 0.04);
    }

    .custom-scroll::-webkit-scrollbar-thumb
    {
        background-color: rgba(0, 0, 0, 0.2);
        // border: 2px solid #555555;
    }

    .nav-item  {
       .active
       {
		background-color:tomato;
		//background-color:white;
       }


    }
