@mixin custom-bg-variant($parent, $color) {
    #{$parent} {
      background-color: $color !important;
    }
    a#{$parent} {
      @include hover-focus {
        background-color: darken($color, 10%) !important;
      }
    }
  }

$main-font: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$mono-font: "Monaco", "Menlo", monospace;
$main-font-size: 14px;


.beta { color: color("purple"); }

$colors: (
    blue: $blue,
    indigo: $indigo,
    purple: $purple,
    pink: $pink,
    red: $red,
    orange: $orange,
    yellow: $yellow,
    green: $green,
    teal: $teal,
    cyan: $cyan,
    white: $white,
    gray: $gray-600,
    gray-dark: $gray-800
  ) !default;


$custom-colors:(
    color1: rgb(15, 52, 82),
    color2: rgb(13, 88, 86),
    color3: rgb(43, 19, 112),
    color4: rgb(107, 35, 34),
    color5: rgb(80, 43, 97),
    darkLight:#3e454d,

    pro1:#21295C,
    pro1-light:#353C6A,
    pro1-sec:#C8C2AE,
    pro1-sec-dark:#B6B19F,

    pro2:#008861,
    pro2-light:#17AE82,
    pro2-sec:#FBECE8,
    pro2-sec-dark:#F9DDD6,


    //ucsb: rgb(22, 31, 44),
	//ucsb: #384259,
	ucsb: #222c3b,
	//ucsb: #34526e,
    //ucsb-light: #34526e,
	//ucsb-light: #34526e,
    //ucsb-light: #3f72af,
	ucsb-light: #384259,
	//ucsb-light: rgba($purple,.2),
    ucsb-dark: rgb(22, 31, 44),
    ucsb-yellow: #72694f,
    ucsb-gray:#727272


)!default;


  $custom-theme-colors: (
    primary: $blue,
    secondary: #ffce34,
    success: $green,
    info: $cyan,
    warning: $yellow,
    danger: $red,
    light: $gray-100,
    dark: $gray-800
  ) !default;

@each $color, $value in $custom-colors {
    @include custom-bg-variant('.c-#{$color}', $value);
}
